<template>
  <div class="login">
    <h1>登录</h1>
    <div class="form-group">
      <h3>用户名:</h3>
      <input type="text" id="username" v-model="username">
    </div>
    <div class="form-group">
      <h3>密码:</h3>
      <input type="password" id="password" v-model="password">
    </div>
    <div class="button-group">
      <button @click="login">登录</button>
      <button @click="$router.push('/register')">注册</button>
    </div>  
  </div>
</template>

<script>
import { login } from '@/api/api';
import { currentUser } from '@/api/api';

export default {
  name: 'MyLogin',
  data() {
    return{
      username: '',
      password: '',
      token: '',
      responseData: null,
    }
  },
  methods: {
    async login(){
      try {  
        const postData = {  
          username: this.username,
          password: this.password,
        };  
        //发送请求
        const response = await login(postData);   
        if(response.code===200){
          this.responseData=response;
          this.token = response.data;
          //记录token
          this.$store.commit('setToken',this.token);
          this.$store.commit('setIfLogin',true);
          this.$store.commit('setUsername',this.username);
          this.getCurrentUser();
          alert('登录成功！');
          //路由到文章列表
          this.$router.push('/articles/any/page');
        }
        else{
          alert(response.data.msg);
        }
      } 
      catch (error) {  
        console.error('发送 POST 请求时出错:', error);
      } 
    },
    //登录时,获取当前用户信息(主要是权限),存入store
     async getCurrentUser(){
         try {    
           const response = await currentUser();  
           this.responseData=response;
           this.$store.commit('setRoleId',response.data.roleId);
           this.$store.commit('setUserId',response.data.id);
           this.$store.commit('setUsername',response.data.username);
         } catch (error) {  
           console.error('getCurrentUser请求时出错:', error);
         }
       },
  }
}
</script>

<style scoped>
.login {
  width: 100%;
  max-width: 500px;
  background-color: #f7f7f7;
  padding: 40px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 50px auto;
  background: linear-gradient(150deg, #e6e6e6, #ffffff);
  text-align: center;
}

h2 {
  margin-bottom: 40px;
  color: #333;
  font-size: 24px;
}

.form-group {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
}

.form-group p,
.form-group input {
  padding: 10px;
  font-size: 14px;
  color: #666;
  width: 100%; 
}

.form-group p {
  margin-bottom: 8px;
}

.form-group input {
  border: 2px solid #ddd;
  border-radius: 6px;
  box-sizing: border-box;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group input:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

button {
  padding: 15px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  flex-grow: 1;
  margin: 5px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

button:first-child {
  background-color: #007BFF;
  color: white;
}

button:first-child:hover {
  background-color: #0056b3;
  transform: translateY(-3px);
}

button:last-child {
  background-color: #6c757d;
  color: white;
}

button:last-child:hover {
  background-color: #545b62;
  transform: translateY(-3px);
}
</style>