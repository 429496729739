<template>
  <div class="tags-container">
    <h2>标签</h2>

    <!-- 遍历所有文章标签 -->
    <div class="tags-list">
      <div
        v-for="item in tags"
        :key="item.id"
        class="tag-item">

        <div class="tag-content">
          <!-- 根据标签获取所有文章 -->
          {{item.name}}标签下的文章:
          <button @click="$router.push(`/articles/any/allTags/${item.name}`)" class="btn">查看</button>
        </div>
      </div>
    </div>
      
    <div class="top">
      <!--路由出口,组件展示的位置-->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { getTags } from '@/api/api';

export default {
  name: 'MyTags',

  data() {
    return {
      tags: [],
      responseData: null,
    }
  },
  //获取所有文章标签
  async created() {
    try {
      const response = await getTags();
      this.responseData = response;
      this.tags = response.data;
    } catch (error) {
      console.error('发送 GET 请求时出错:', error);
    }
  },

  methods: {
  }
}
</script>

<style scoped>
.tags-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.tag-item {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: calc(80%);
}

.tag-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn {
  padding: 8px 15px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  font-size: 14px;
}

.btn:hover {
  background-color: #5a6268;
}

</style>