<template>
  <div class="article-container">
    <!-- 文章相关: -->
    <h1> {{ article.title }}</h1>
    <h3>文章内容</h3>
    <pre class="articleContent">{{ article.content }}</pre>
    <p>点击查看文章详情:<a :href="article.articleUrl" target="_blank">文章详情</a></p>
    <button @click="toggleReply(1)" v-if="!showReplies[1]">评论文章</button>
    <div v-else>
      评论内容: <input type="text" v-model="content">
      <button @click="submitReply(1)">提交</button>
      <button @click="toggleReply(1)">取消</button>
    </div>

    <h2>评论:</h2>
    <!-- 一级评论 -->
    <div v-for="item in comments" :key="item.id" class="comment">
      <div class="commenter-info">
        <p class="commenterId">评论人: {{ item.commenterId }}</p>
        <p class="content">评论内容: <br>{{ item.content }}</p>
        <p class="update-time">评论时间: {{ item.updateTime }}</p>
      </div>
      
      <!-- 二级评论 -->
      <div v-for="item2 in item.childComments" :key="item2.id" class="childComment">
        <div class="commenter-info">
          <p class="reply">{{ item2.commenterId }} 回复: {{ item2.toUid }}</p>
          <p class="content">回复内容: <br>{{ item2.content }}</p>
          <p class="update-time">评论时间: {{ item2.updateTime }}</p>
        </div>
        <button @click="toggleReply(3, item2.id)" v-if="!showReplies[3][item2.id]">回复</button>
        <div v-else>
          评论内容: <input type="text" v-model="content">
          <!-- (3:二级评论 (item.id:父评论 (item2.id:评论唯一标识 (item2.commenterId:回复的对象 -->
          <button @click="submitReply(3, item.id, item2.id, item2.commenterId)">提交</button>
          <button @click="toggleReply(3, item2.id)">取消</button>
        </div>
      </div> 
      <!-- 回复评论 -->
      <button @click="toggleReply(2, item.id)" v-if="!showReplies[2][item.id]">回复</button>
      <div v-else>
        评论内容: <input type="text" v-model="content">
        <!-- (3:二级评论 (item.id:父评论 (item.id:评论唯一标识 (item.commenterId:回复的对象 -->
        <button @click="submitReply(2, item.id, item.id, item.commenterId)">提交</button>
        <button @click="toggleReply(2, item.id)">取消</button>
      </div>
    </div>   
  </div>
</template>

<script>
import { getComments, ViewArticles, createComment } from '@/api/api';

export default {
  data() {
    return {
      article: null,
      articleId: null,
      comments: [],
      responseData: null,
      content: null,
      showReplies: {
        1: false,
        2: {},
        3: {},
      },
    }
  },
  created() {
    this.ViewArticles();
    this.getComments();
  },

  methods: {
    //显示文章详情
    async ViewArticles() {
      try {
        this.articleId = this.$route.params.words;
        const response = await ViewArticles(this.articleId);
        this.responseData = response;
        this.article = response.data;
      } catch (error) {
        console.error('ViewArticles请求时出错:', error);
      }
    },
    //获取文章评论
    async getComments() {
      try {
        const response = await getComments(this.articleId);
        this.responseData = response;
        this.comments = response.data;
      } catch (error) {
        console.error('getComments请求时出错:', error);
      }
    },
    //回复
    async submitReply(type, parentId ,replyId ,to) {
      try {
        const postData = {
          articleId: this.articleId,
          content: this.content,
          parentId: type === 1 ? null : parentId, 
          toUid: type === 1 ? null : to,
        };
        const response = await createComment(postData);
        this.responseData = response;
        //评论的唯一标识id=item.id/item2.id
        const id= type ===1 ? null : replyId;
        this.toggleReply(type, id);
        this.getComments();
        this.content=null;
      } catch (error) {
        console.error('submitReply请求时出错:', error);
      }
    },
    //切换回复框显示/隐藏:
    //type=1:回复文章 type=2:回复一级评论 type=3:回复二级评论
    //id是评论的唯一标识，决定评论框的显示隐藏
    toggleReply(type, id = null) {
      if (this.$store.state.token === null) {
        alert('登录后方能评论');
        this.$router.push('/login');
        return;
      }
      if (type === 1) {
        this.showReplies[1] = !this.showReplies[1];
      } else if (type === 2 || type === 3) {
        //null/false设置为true，主要处理是null的情况：
        if (!this.showReplies[type][id]) {
          this.$set(this.showReplies[type], id, true);
        } 
        else {
          this.showReplies[type][id] = !this.showReplies[type][id];
        }
      } else {
        return;
      }
    }
  }
}
</script>

<style scoped>
.article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

p {
  margin-bottom: 20px;
}

a {
  color: #007BFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  padding: 5px 12px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
}

button:hover {
  background-color: #5a6268;
}

.articleContent {
  border: 1px solid #000;
  overflow-wrap: break-word; 
  word-wrap: break-word; 
  max-height: 300px; 
  overflow: auto; /* 超出最大高度时出现滚动条 */
  padding: 10px;
}

.comment {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
}

.commenter-info {
  flex-grow: 1;
}

.commenterId, .content, .update-time {
  margin-bottom: 5px;
}

.commenterId {
  font-weight: bold;
}

.content {
  color: #333;
}

.update-time {
  font-size: 12px;
  color: #666;
}

.replies {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.childComment {
  margin-bottom: 5px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

.reply {
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>