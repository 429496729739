import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//创建仓库
const store = new Vuex.Store({
  //开启严格模式:上线时需移除(消耗性能)
  //strict: true,

  //所有组件共享的数据
  state: {
    token: null,
    userId: '',
    roleId: '',
    username: '',
    ifLogin: false,
  },
  getters: {
  },
  //修改state的方法
  mutations: {
    setToken(state,newToken){
      state.token = newToken;
    },
    setUserId(state,newUserId){
      state.userId = newUserId;
    },
    setRoleId(state,newRoleId){
      state.roleId = newRoleId;
    },
    setUsername(state,newUsername){
      state.username = newUsername;
    },
    setIfLogin(state,newifLogin){
      state.ifLogin = newifLogin;
    }
  },
  actions: {
  },
  modules: {
  }
})

//导出给main.js
export default store
