<template>
    <button class="button">
      全局组件{{ name+num }}
    </button>
  </template>
  
  <script>
  export default {
    //如果只指定数据类型,可以不用{}
    //1.type:数据类型
    //2.required:是否非空
    //3.default:默认值
    props:{
        name: String,
        num:{
            type: Number,
            //required: true,
            default: 0,
        }

    }
  }
  </script>
  
  <!--scoped局部样式:如果不加style会对全局造成影响-->
  <style scoped>
  .button{
      height: 50px;
      line-height: 50px;
      padding: 0;
      background-color:brown;
      border-radius: 5px ;
  }
  </style>