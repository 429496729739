<template>
    <div class="tags-container">
      <h2>标签</h2>
  
      <!-- 添加用户 -->
      <h3>管理员添加标签:</h3>
      <button @click="changeShow" v-if="show" class="btn">添加标签</button> 
      <div class="add-tag" v-else>
        <h3>添加标签:</h3>
        标签名: <input type="text" v-model="tagName" class="input-field"><br><br>
        <button @click="addTag" v-if="$store.state.roleId===1" class="btn" >添加</button>
        <button @click="changeShow" class="btn">取消</button>
      </div>
      <br>
  
      <div class="tags-list">
        <div v-for="item in tags"
          :key="item.id"
          class="tag-item">
          <p class="id">标签ID: {{ item.id }}</p>
          <p class="name">标签名: {{ item.name }}</p>
          <button @click="deleteTag(item.id)" v-if="$store.state.roleId===1" class="btn">删除</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getTags } from '@/api/api';
  import { addTag } from '@/api/api';
  import { deleteTag } from '@/api/api';
  
  export default {
    name: 'MyTags',
  
    data() {
      return {
        tagName: null,
        show: true,
        tags: [],
        responseData: null,
      }
    },
    // 获取所有文章标签
    created() {
      this.getTags();
    },
  
    methods: {
      async getTags() {
        try {
          const response = await getTags();
          this.responseData = response;
          this.tags = response.data;
        } catch (error) {
          console.error('getTags请求时出错:', error);
        }
      },
      async deleteTag(id) {
        try {
          const response = await deleteTag(id);
          this.responseData = response;
          alert('删除成功');
          this.getTags(); // 重新获取标签列表
        } catch (error) {
          console.error('deleteTag请求时出错:', error);
        }
      },
      async addTag() {
        const postData = {  
          name: this.tagName,
        };  
        try {
          const response = await addTag(postData);
          this.responseData = response;
          this.tagName=null;
          alert('添加成功');
          this.getTags(); // 重新获取标签列表
          this.changeShow();
        } catch (error) {
          console.error('addTag请求时出错:', error);
        }
      },
      changeShow() {
        this.show = !this.show;
      },
    }
  }
</script>

<style scoped>
.tags-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.btn {
  padding: 8px 15px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  font-size: 14px;
}

.btn:hover {
  background-color: #5a6268;
}

.add-tag {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 4px;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag-item {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 10px;
  width: calc(33.333% - 10px);
  box-sizing: border-box;
}

.tag-item .id,
.tag-item .name {
  margin-bottom: 5px;
  font-size: 14px;
}

.tag-item .name {
  font-weight: bold;
}

</style>