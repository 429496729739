<template>
  <div class="App">
    <div class="footer_wrap">
      <!--router-link to=""就是a标签 herf="#"的高亮效果-->
      <router-link to="/login" v-if="$store.state.ifLogin===false" class="footer_link">登录 </router-link>
      <router-link to="/articles/any/page" class="footer_link">文章 </router-link>
      <router-link to="/articles/any/allTags" class="footer_link">分类 </router-link>
      <router-link to="/currentUser" class="footer_link">用户信息</router-link>
      <router-link to="/managers" v-if="$store.state.roleId===1" class="footer_link">管理员后台 </router-link>
      <router-link to="/loginUser/logout" v-if="$store.state.ifLogin===true" class="footer_link">退出登录</router-link>    
    </div>   
    <div class="top">
      <!--路由出口,组件展示的位置-->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "layOut",
}
</script>

<style scoped>
.footer_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  padding: 10px 0;
  border-radius: 5px;
}

.footer_link {
  flex: 1;
  padding: 10px;
  text-align: center;
  color: #ccc;
  transition: background-color 0.3s, color 0.3s;
  margin: 0 10px;
  border-radius: 5px;
}

.footer_link.router-link-active {
  background-color:#555;
  color: white;
}

.footer_link:hover {
  background-color: #555;
  color: white;
}
</style>