import axios from 'axios';  
import store from '@/store/index'

const axiosInstance = axios.create({  
  //'http://localhost:8080'
  //'http://47.109.44.103:8800'
  baseURL: 'http://47.109.44.103:8800', 
  timeout: 10000, //请求超时时间  
  headers: {  
    //'Content-Type': 'application/json',  
    //全局授权头  
    //'Authorization': store.state.token,
  }  
});  

 //请求拦截器  
axiosInstance.interceptors.request.use(  
    config => {  
      //添加认证 token  
      config.headers['Authorization'] = store.state.token;  
      return config;  
    },  
    error => {  
      //对错误请求进行处理  
      return Promise.reject(error);  
    }  
  ); 

//响应拦截器  
axiosInstance.interceptors.response.use(
  response => {
    const res = response.data;
    //0 为成功状态
    if (res.code !== 200) {

      //90001 Session超时
      if (res.code === 90001) {
        return Promise.reject('error');
      }

      //20001 用户未登录
      if (res.code === 90002) {
        alert('未登录或登录超时，请重新登录')
        return Promise.reject('error');
      }

      //70001 权限认证错误
      if (res.code === 70001) {
        alert('没有权限访问')
        return Promise.reject('error');
      }

      return Promise.reject(res.msg);
    } else {
      return response.data;
    }
  });

export default axiosInstance;