//引入vue-router:(1)下载3.6.5(2)import(3)use(4)创建对象new VueRouter()(5)注入到new Vue中
//(6)创组件,配routes(7)配置路由出口router-view
import Vue from 'vue'
import VueRouter from 'vue-router'
//import User from './modules/user.js'

import Layout from '@/views/Layout.vue'
import UsersManagement from '@/views/UsersManagement.vue'
import Articles from '@/views/Articles.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Register from '@/views/Register.vue'
import Tags from '@/views/Tags.vue'
import ViewArticles from '@/views/ViewArticles.vue'
import GetByTag from '@/views/GetByTag.vue'
import NotFound from '@/views/NotFound.vue'
import CurrentUser from '@/views/CurrentUser.vue'
import WriteArticle from '@/views/WriteArticle.vue'
import Manager from '@/views/Manager.vue'
import TagsManagement from '@/views/TagsManagment.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    //重定向:
    { path: '/', redirect: '/articles/any/page'},
    { path: '/managers', redirect: '/users/page'},
    //设置路由规则:
    { path: '/', component: Layout,
      children: [
        //二级路由:
        { path: '/managers', component: Manager,
          children:[
            { path: '/users/page', component: UsersManagement},
            { path: '/tagsManagement', component: TagsManagement},
          ]
        },
        { path: '/articles/any/page', component: Articles},
        { path: '/articles/any/allTags', component: Tags},
        { path: '/currentUser', component: CurrentUser},
      ]
    },
    
    { path: '/login', component: Login},
    { path: '/loginUser/logout', component: Logout},
    { path: '/register', component: Register},
    { path: '/articles/any/page/:words', component: ViewArticles},
    { path: '/articles/login/add/:words?', component: WriteArticle},
    { path: '/articles/any/allTags/:words', component: GetByTag},
    { path: '*', component: NotFound}
  ],
  //mode: "history"
})

//导出new VueRouter对象,以便main.js使用
export default router