<template>
  <div class="articles-container">
    <h1>{{ tagName }}标签下的所有文章</h1>
    <div
      v-for="item in articles"
      :key="item.id"
      class="article-item">
      <div class="article-content">
        <p class="title"><strong>标题:</strong> {{ item.title }}</p>
        <p class="author"><strong>作者:</strong> {{ item.author }}</p>
        <p class="comment-counts"><strong>评论数:</strong> {{ item.commentCounts }}</p>
        <p class="update-time"><strong>更新时间:</strong> {{ item.updateTime }}</p>
        <!-- 点击事件实现路由(动态根据id查看文章) -->
        <button @click="$router.push(`/articles/any/page/${item.id}`)" class="btn">查看文章</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getByTag } from '@/api/api';

export default {
  data() {
    return {
      tagName: null,
      articles: [],
      responseData: null,
    }
  },
  async created() {
    try {
      this.tagName = this.$route.params.words;
      const response = await getByTag(this.tagName);
      this.responseData = response;
      this.articles = response.data;
    } catch (error) {
      console.error('发送 GET 请求时出错:', error);
    }
  },
}
</script>

<style scoped>
.articles-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.article-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
}

.article-content p {
  margin: 8px 0;
}

.article-content p strong {
  color: #333;
}

.btn {
  padding: 8px 15px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  font-size: 14px;
}

.btn:hover {
  background-color: #5a6268;
}
</style>