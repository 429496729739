<template>
  <div class="articles-container">
    <!-- 发表文章 -->
    <h3>发表文章:</h3>
    <button @click="push" class="btn">发表文章</button>

    <!-- 文章查询条件 -->
    <div class="search">
      <h3>查询文章:</h3>
      <div class="search-fields">
        <!-- 标题/作者 -->
        <div class="field-group">
          文章标题: 
          <input type="text" v-model="title" class="input-field">
          作者: 
          <input type="text" v-model="author" class="input-field">
        </div>
        <!-- 日期范围 -->
        <div class="field-group">
          <div class="date-picker-container">
            <span>更新日期:</span><br>
            <input type="date" v-model="selectedTimeFrom" class="date-input">
            <input type="date" v-model="selectedTimeTo" class="date-input">
          </div>
        </div>
        <!-- 排序 -->
        <div class="field-group">
          排序字段: 
          <select v-model="sortBy" class="input-field">
            <option v-for="option in optionsSortBy" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          升序/降序:
           <select v-model="isAsc" class="input-field">
            <option v-for="option in optionsIsAsc" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
        <!-- 页数 -->
        <div class="field-group">
          页数: <input type="text" v-model="pageNo" class="input-field">
          单页文章数: <input type="text" v-model="pageSize" class="input-field">
        </div>
      </div>
      <button @click="search(0)" class="btn">查询</button>
    </div>

    <!-- 文章列表 -->
    <h2>文章列表</h2>
    <button @click="search(-1)" class="btn">上一页</button>
    <button @click="search(1)" class="btn">下一页</button>
    
    <div v-for="item in articles" :key="item.id" class="article-item">
      <div class="article-info">
        <p class="title">标题: {{ item.title }}</p>
        <p class="author">作者: {{ item.author }}</p>
        <p class="comment-counts">评论数: {{ item.commentCounts }}</p>
        <p class="update-time">更新时间: {{ item.updateTime }}</p>
        <!-- 标签 -->
        <div class="tags-container">
          <div v-for="tag in item.tags" :key="tag.id" class="tag-item">
            <span v-if="tag.name !== 'Default'" class="tag">{{ tag.name }}</span>
          </div>
        </div>
        <button @click="$router.push(`/articles/any/page/${item.id}`)" class="btn">查看文章</button>
        <button @click="deleteArticle(item.id)" v-if="$store.state.roleId===1" class="btn">删除</button>
      </div>
    </div>

    <div class="top">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
//封装好的请求
import { getArticlesPage } from '@/api/api.js';
import { deleteArticle } from '@/api/api.js';
import { getByArticle } from '@/api/api.js';

export default {
  name: 'MyArticles',
  data() {
    return {
      pageNo: 1,
      pageSize: 5,
      title: null,
      author: null,
      selectedTimeFrom: null,
      selectedTimeTo: null,
      isAsc: 'false',
      articles: [],
      tags: [],
      responseData: null,
      sortBy: 'update_time',
      optionsSortBy: [
        { value: 'update_time', text: '更新时间' },
        { value: 'title', text: '标题' },
        { value: 'comment_counts', text: '评论数' },
      ],
      optionsIsAsc: [
        { value: 'false', text: '降序' },
        { value: 'true', text: '升序' },
      ]
    }
  },
  created() {
    this.search(0);
  },
  methods: {
    async search(change) {
      this.pageNo+=change;
      try {
        const postData = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          title: this.title,
          author: this.author,
          timeFrom: this.selectedTimeFrom,
          timeTo: this.selectedTimeTo,
          sortBy: this.sortBy,
          isAsc: this.isAsc,
        };
        const response = await getArticlesPage(postData);
        this.responseData = response;
        this.articles = response.data.list;
        // 获取每篇文章的标签
        this.articles.forEach(async (article) => {
          const tagsResponse = await getByArticle(article.id);
          article.tags = tagsResponse.data;
        });
      } catch (error) {
        console.error('search请求时出错:', error);
      }
    },
    async deleteArticle(id) {
      try {
        const response = await deleteArticle(id);
        this.responseData = response;
        this.search(0);
        alert("删除成功");
      } catch (error) {
        console.error('deleteArticle请求时出错:', error);
      }
    },
    push() {
      if (this.$store.state.token === null) {
        alert('登录后方能发布文章');
        this.$router.push('/login');
        return;
      }
      this.$router.push('/articles/login/add');
    }
  }
}
</script>

<style scoped>
.articles-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.btn {
  padding: 8px 15px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  font-size: 14px;
}

.btn:hover {
  background-color: #5a6268;
}

.search {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 4px;
}

.search-fields {
  display: flex;
  flex-direction: column; 
  gap: 10px; 
}

/* 字段组样式 */
.field-group {
  display: flex;
  align-items: center; 
  margin-bottom: 10px; 
  gap: 10px; 
}

/* 字段组中的标签样式 */
.field-group label,
.field-group span {
  margin-right: 10px; 
  white-space: nowrap; 
}

/* 输入框样式 */
.input-field,
.date-input {
  padding: 6px 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  flex-grow: 1; 
}

.article-item {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.article-info {
  display: flex;
  flex-direction: column;
}

.article-info .title {
  font-weight: bold; /* 标题加粗 */
  margin-bottom: 8px;
}

.article-info .author {
  margin-bottom: 8px;
}

.article-info .comment-counts {
  font-size: 12px; 
  color: #6c757d;
  margin-bottom: 8px;
}

.article-info .update-time {
  font-size: 12px; 
  color: #6c757d;
}

/* 日期选择器容器样式 */
.date-picker-container {
  display: flex;
  align-items: center;
  gap: 10px; /* 日期输入框之间的间距 */
}

/* 单个日期输入框样式 */
.date-input {
  padding: 6px 10px;
  border:1px solid #ced4da;
border-radius: 4px;
font-size: 14px;
max-width: 200px; 
}

.top {
min-height: 300px;
}


.tags-container {
  display: flex; /* 使用flex布局 */
  flex-wrap: wrap; /* 允许标签在必要时换行 */
  gap: 5px; 
}

.tag {
  white-space: nowrap; 
  font-size: 14px; 
  color: #6c757d; 
  background-color: #f8f9fa; 
  padding: 3px 8px; 
  border: 1px solid #dee2e6; 
  border-radius: 4px; 
  display: inline-block; 
}

.tag:hover {
  background-color: #e2e6ea; 
  color: #333; 
  border-color: #adb5bd; 
  cursor: pointer; 
}


</style>