import axiosInstance from './axiosInstance';  
//分页查询文章
export const getArticlesPage = (postData) => {  
  return axiosInstance.post('/articles/any/page', postData);  
};
//查看文章
export const ViewArticles = (articleId) => {  
  return axiosInstance.get(`/articles/any/page/${articleId}`);  
};  
//添加文章
export const addArticle = (postData) => {  
  return axiosInstance.post('/articles/login/add', postData);  
};
//上传文章
export const upLoadFile = (postData) => {
  return axiosInstance.post('/upload',postData);
} 
//更新文章
export const updateArticle = (userData) => {  
  return axiosInstance.put('/articles/login/update', userData);  
}; 
//删除文章
export const deleteArticle = (id) => {  
  return axiosInstance.delete(`/articles/login/delete/${id}`);  
};
//查询文章下的评论
export const getComments = (articleId) => {  
  return axiosInstance.get(`/comments/articles/${articleId}`);  
};
//写评论
export const createComment = (postData) => {  
  return axiosInstance.post('/comments/create/change', postData);  
};
//获取所有标签
export const getTags = () => {  
  return axiosInstance.get('/articles/any/allTags');  
}; 
//获取标签下的文章
export const getByTag = (tagName) => {  
  return axiosInstance.get(`/articles/any/allTags/${tagName}`);  
};   
//获取文章下的标签
export const getByArticle = (articleId) => {  
  return axiosInstance.get(`/articles/any/getTags/${articleId}`);  
}; 
//分页查询用户
export const getUsersPage = (postData) => {  
  return axiosInstance.post('/users/page', postData);  
};
//登录
export const login = (postData) => {  
  return axiosInstance.post('/login', postData);  
};
//注册
export const register = (postData) => {  
  return axiosInstance.post('/register', postData);  
}; 
//退出登录
export const logout = () => {  
  return axiosInstance.get('/loginUser/logout');  
}; 
//添加标签
export const addTag = (postData) => {  
  return axiosInstance.post('/admins/Tags/add', postData);  
};
//删除标签
export const deleteTag = (tagId) => {  
  return axiosInstance.delete(`/admins/Tags/delete/${tagId}`);  
};  
//更新用户
export const updateUser = (userData) => {  
  return axiosInstance.put('/users/update', userData);  
};  
//删除用户
export const deleteUser = (userId) => {  
  return axiosInstance.delete(`/admins/delete/${userId}`);  
};  
//添加用户
export const addUser = (postData) => {  
  return axiosInstance.post('/admins/add', postData);  
};
//当前登录的用户
export const currentUser = () => {  
  return axiosInstance.get('/users/currentUser');  
};
//根据作者查询文章
export const getByAuthor = (authorName) => {  
  return axiosInstance.get(`/articles/any/author/${authorName}`);  
}; 
