<template>
    <div class="App">
      <div class="footer_wrap">
        <!--router-link to=""就是a标签 herf="#"的高亮效果-->
        <router-link to="/users/page"  class="footer_link">用户管理 </router-link> 
        <router-link to="/tagsManagement"  class="footer_link">标签管理 </router-link>
      </div>   
      <div class="top">
        <!--路由出口,组件展示的位置-->
        <router-view></router-view>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "layOut",
  }
  </script>
  
  <style scoped>
  .footer_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333;
    padding: 10px 0;
    border-radius: 5px;
  }
  
  .footer_link {
    flex: 1;
    padding: 10px;
    text-align: center;
    color: #ccc;
    transition: background-color 0.3s, color 0.3s;
    margin: 0 10px;
    border-radius: 5px;
  }
  
  .footer_link.router-link-active {
    background-color:#555;
    color: white;
  }
  
  .footer_link:hover {
    background-color: #555;
    color: white;
  }
  </style>