<template>
  <div class="write_article">
    <h1>发布/更新文章</h1>
    <!-- 文章标题/内容-->
    <div class="form-group">
      <label for="title">文章标题:</label>
      <input type="text" id="title" v-model="title" placeholder="请输入文章标题" class="title-input">
    </div>
    <!-- 选择文章标签 -->
    <div class="form-group">
      <label for="article-tags" class="article-tags-label">选择文章标签(可多选)：</label>
      <select v-model="tags" class="input-field select-multiple" multiple id="article-tags">
        <option v-for="option in optionsTags" :key="option.id" :value="option.name">
          {{ option.name }}
        </option>
      </select>
    </div>
    <!-- 输入文章内容 -->
    <div class="form-group">
      <label for="content">文章内容:</label>
      <textarea id="content" v-model="content" placeholder="请输入文章内容" rows="10" class="text-area"></textarea>
    </div>
    <!-- 上传文章文件 -->
    <div class="form-group">
      <label for="file-upload">上传文章详情:</label>
      <input type="file" id="file-upload" @change="handleFileUpload" class="file-input">
      <button class="file-button" @click="uploadFile">确认上传文章</button>
    </div>
    <div class="form-group">
      <label for="title">文章详情路径(上传文章后自动生成):</label>
      <input type="text" v-model="articleUrl" placeholder="文章详情路径" class="title-input">
    </div>
    <!-- 提交 -->
    <button class="submit-button" @click="addArticle">提交</button>
  </div>
</template>

<script>
import { addArticle } from '@/api/api';
import { upLoadFile } from '@/api/api';
import { ViewArticles } from '@/api/api';
import { getTags } from '@/api/api';

export default {
  data() {
    return {
      articleId: '',
      title: '',
      content: '',
      articleUrl: '',
      file: null,
      tags: ['Default'],
      optionsTags: [],
      token: '',
      responseData: null,
    }
  },
  created() {
    this.getTagList();
    if(this.$route.params.words!=null){
      this.ViewArticles();
    }
  },
  methods: {
    async ViewArticles() {
      try {
        this.articleId = this.$route.params.words;
        const response = await ViewArticles(this.articleId);
        this.responseData = response;
        this.title = response.data.title;
        this.content = response.data.content;
        this.articleUrl =response.data.articleUrl;
      } catch (error) {
        console.error('ViewArticles请求时出错:', error);
      }
    },
    handleFileUpload(event) {
      this.file = event.target.files[0]; // 获取选择的文件
    },
    async addArticle() {
      try {
        const postData = {
          id: this.articleId,
          title: this.title,
          articleUrl: this.articleUrl,
          content: this.content,
          tags: this.tags,
        }
        const response = await addArticle(postData);
        this.responseData = response;
        alert('发布成功');
        this.$router.push('/articles/any/page');
      } catch (error) {
        console.error('addArticle请求时出错:', error);
      }
    },
    async uploadFile() {
      try {
        const formData = new FormData();
        formData.append('image', this.file);

        const response = await upLoadFile(formData);
        this.responseData = response;
        this.articleUrl = response.data;
      } catch (error) {
        console.error('uploadFile请求时出错:', error);
      }
    },
    async getTagList() {
      try {
        const response = await getTags();
        this.responseData = response;
        this.optionsTags = response.data;
      } catch (error) {
        console.error('getTags请求时出错:', error);
      }
    },
  }
}
</script>

<style>
.write_article {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.title-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 18px; 
  transition: border-color 0.3s, box-shadow 0.3s;
}

.title-input:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

.text-area {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  resize: vertical;
  min-height: 200px;
}

.file-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  margin-bottom: 20px;
}

.select-multiple {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  background-color: #f9f9f9;
  appearance: none; /* 移除默认的下拉箭头 */
  background-repeat: no-repeat;
  background-position: right 10px center;
  cursor: pointer;
}

.select-multiple:focus {
  border-color: #007BFF;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  outline: none; /* 移除焦点时的轮廓线 */
}

.submit-button {
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

a {
  color: #007BFF;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #0056b3;
}
</style>