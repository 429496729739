<template>
  <div class="user-container">
    <h1>用户信息</h1>

    <!-- 固定展示用户信息 -->
    <div v-if="show" class="user-info">
      <p>用户名: {{ username }}</p> <br>
      <p>邮箱: {{ email }}</p> <br>
      <p>手机号: {{ mobilePhoneNumber }}</p> <br>
      <p v-if="roleId===0">权限: 普通用户</p>
      <p v-if="roleId===1">权限: 管理员</p>
      <br>
      <button @click="changeShow" class="btn">修改信息</button>
    </div>

    <!-- 修改用户信息的框 -->
    <div v-else class="user-edit">
      <p>用户名: <input type="text" v-model="username" class="input-field"></p> <br>
      <p>邮箱: <input type="text" v-model="email" class="input-field"></p> <br>
      <p>手机号: <input type="text" v-model="mobilePhoneNumber" class="input-field"></p> <br>
      <button @click="update" class="btn">提交</button>
      <button @click="changeShow" class="btn">取消</button>
    </div>    

    <!-- 用户曾发布的所有文章 -->
    <h2>用户发布的文章:</h2>
    <div v-for="item in articles" :key="item.id" class="article-item">
      <div class="article-info">
        <p class="title">标题: {{ item.title }}</p>
        <p class="update-time">更新时间: {{ item.updateTime }}</p>
        <button @click="$router.push(`/articles/any/page/${item.id}`)" class="btn">查看文章</button>
        <button @click="$router.push(`/articles/login/add/${item.id}`)" class="btn">更新文章</button>
        <button @click="deleteArticle(item.id)" class="btn">删除</button>
      </div>
    </div>
  </div>
</template>

<script>
  import { currentUser } from '@/api/api';
  import { updateUser } from '@/api/api';
  import { getByAuthor } from '@/api/api';
  import { deleteArticle } from '@/api/api';

  export default {
    data(){
      return{
        responseData: null,
        id: null,
        username: null,
        roleId: null,
        email: null,
        mobilePhoneNumber: null,
        articles: [],
        show: true,
      }
    },
  
    async created (){
      this.getCurrentUser();
      this.getByAuthor();
    },
  
    methods: { 
      async getCurrentUser(){
        try {    
          const response = await currentUser();  
          this.responseData=response;
          this.id = response.data.id;
          this.username=response.data.username;
          this.roleId=response.data.roleId;
          this.email=response.data.email;
          this.mobilePhoneNumber=response.data.mobilePhoneNumber;
        } catch (error) {  
          console.error('getCurrentUser请求时出错:', error);
        }
      },
      async update(){
        try {    
          const postData = {  
            id: this.id,
            username: this.username,
            email: this.email,
            mobilePhoneNumber: this.mobilePhoneNumber,
          };  
          const response = await updateUser(postData);  
          this.responseData=response;
          
          if (response) {
            // 更新组件的data字段以反映最新的用户信息
            this.id = response.data.id;
            this.username = response.data.username;
            this.email = response.data.email;
            this.mobilePhoneNumber = response.data.mobilePhoneNumber;
            this.show = !this.show;
            alert("修改成功");
          }
        } catch (error) {  
          console.error('updateUser请求时出错:', error);
        }
      },
      async getByAuthor(){
        try {    
          const response = await getByAuthor(this.$store.state.username);  
          this.responseData=response;
          this.articles=response.data;
        } catch (error) {  
          console.error('getByAuthor请求时出错:', error);
        }
      },
      async deleteArticle(id) {
        try {
          const response = await deleteArticle(id);
          this.responseData = response;
          this.getByAuthor();
          alert("删除成功");
        } catch (error) {
          console.error('deleteArticle请求时出错:', error);
        }
      },
      changeShow(){
        if(this.$store.state.token===null){
        alert('请登录');
        this.$router.push('/login');
        return;
      }
      this.show=!this.show;
    }
  }
}
</script>

<style scoped>
.user-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.user-info, .user-edit {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.user-info p, .user-edit p {
  margin: 5px 0;
}

.input-field {
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 20px);
}

.btn {
  padding: 8px 15px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  font-size: 14px;
}

.btn:hover {
  background-color: #5a6268;
}


.article-item {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.update-time {
  font-size: 12px;
  color: #666;
  margin-bottom: 10px;
}
</style>