//入口文件，基于App.vue渲染index.html

//导入Vue核心包
import Vue from 'vue'
//导入App.vue根组件
import App from './App.vue'
//导入全局组件
import HmBotton from './components/HmBotton.vue'
//导入router
import router from './router'
//导入vuex仓库
import store from '@/store/index'

//提示:当前处于什么生产环境(false不提示)
Vue.config.productionTip = false

//全局注册组件:组件名,组件对象
Vue.component('HmBotton',HmBotton)

//vue实例化:render方法,基于App.vue渲染index.html.
//.$mount('#app')就相当于el: '#app',用于指定vue管理的容器
new Vue({
  render: h => h(App),
  router: router,
  store: store
}).$mount('#app')
