<template>
  <div>
  </div>
</template>

<script>
import { logout } from '@/api/api';
export default {
  name: 'MyLogout',

  created(){
    this.logout()
  },

  methods: {
    async logout(){
      try {    
        const response = await logout();  
        if(response.code===200){
          this.responseData=response;
          //退出后删除token
          this.$store.commit('setToken','');
          this.$store.commit('setRoleId','');
          this.$store.commit('setUserId','');
          this.$store.commit('setUsername','');
          //设置成未登录
          this.$store.commit('setIfLogin',false);
          alert('成功退出登录');
          //路由到文章列表
          this.$router.push('/articles/any/page');
        }
        else{
          alert(response.data.msg);
        }
      } 
      catch (error) {  
        console.error('发送 POST 请求时出错:', error);
      } 
    }
  }
}
</script>

<style>

</style>