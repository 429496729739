<template>
  <div class="admin-container">
    <h1>管理员后台</h1>

    <!-- 添加用户 -->
     <h3>管理员添加用户:</h3>
    <button @click="changeShow" v-if="show" class="btn">管理员添加用户</button> 
    <div class="add-user" v-else>
      <h3>添加用户:</h3>
      账号: <input type="text" v-model="addUsername" class="input-field"><br><br>
      密码: <input type="password" v-model="addPassword" class="input-field"><br><br>
      权限: 
      <select v-model="addRoleId" class="input-field">
        <option v-for="option in optionsAddRoleId" :key="option.value" :value="option.value">
          {{ option.text }}
        </option>
      </select> <br><br>
      <button @click="addUser" class="btn">添加</button>
      <button @click="changeShow" class="btn">取消</button>
    </div>
    <br>

    <div class="search">
      <h3>查询用户</h3>
      <!-- 查询条件 -->
      <div class="search-fields">
        <div class="field-group">
          用户名: <input type="text" v-model="username" class="input-field">
          权限:
          <select v-model="roleId" class="input-field">
            <option v-for="option in optionsRoleId" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
        <div class="field-group">
          排序字段: <select v-model="sortBy" class="input-field">
            <option v-for="option in optionsSortBy" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
          升序/降序: 
          <select v-model="isAsc" class="input-field">
            <option v-for="option in optionsIsAsc" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </select>
        </div>
        <div class="field-group">
          页数: <input type="text" v-model="pageNo" class="input-field">
          单页文章数: <input type="text" v-model="pageSize" class="input-field">
        </div>
      </div>
      <br>
      <button @click="getUsers(0)" class="btn">查询</button> 
    </div>

    <!-- 分页查询用户 -->
    <button @click="getUsers(-1)" class="btn">上一页</button>
    <button @click="getUsers(1)" class="btn">下一页</button>
    <div
      v-for="item in users"
      :key="item.id"
      class="article-item">
      <div v-if="show2" class="article-info">
        <p class="id">用户ID: {{ item.id }}</p>
        <p class="username">用户名: {{ item.username }}</p>
        <p class="role" v-if="item.roleId===0">角色: 普通用户</p>
        <p class="role" v-if="item.roleId===1">角色: 管理员</p>
        <p class="update-time">更新时间: {{ item.updateTime }}</p>
        <!-- 删除用户 -->
        <button @click="removeUser(item.id)" class="btn">删除</button> 
      </div>
    </div>
    
    <div class="top">
      <!--路由出口,组件展示的位置-->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { getUsersPage } from '@/api/api';
import { deleteUser } from '@/api/api';
import { addUser } from '@/api/api';

export default {
  name: 'MyAdmin',
  data() {
    return {
      show: true,
      show2: true,
      pageNo: 1,
      pageSize: 5,
      username: null,
      users: [], 
      roleId: null,
      optionsRoleId: [
        { value: null, text: '全部' },
        { value: 0, text: '普通用户' },
        { value: 1, text: '管理员' },
      ],
      responseData: null,
      addUsername: null,
      addPassword: null,
      addRoleId: null,
      optionsAddRoleId: [
        { value: 0, text: '普通用户' },
        { value: 1, text: '管理员' },
      ],
      sortBy: 'update_time',
      optionsSortBy: [
        { value: 'update_time', text: '更新时间' },
        { value: 'username', text: '用户名' },
        { value: 'id', text: '用户ID' },
      ],
      isAsc: 'false',
      optionsIsAsc: [
        { value: 'false', text: '降序' },
        { value: 'true', text: '升序' },
      ]
    }
  },
  created() {
    this.getUsers(0);
  },
  methods: {
    async removeUser(id) {
      try {
        await deleteUser(id);
        alert('删除成功');
        this.getUsers(0); 
      } catch (error) {
        console.error('发送 DELETE 请求时出错:', error);
      }
    },
    async getUsers(change) {
      this.pageNo+=change;
      try {  
        const postData = {  
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          username: this.username, 
          roleId: this.roleId,
          isAsc: this.isAsc,
          sortBy: this.sortBy,
        };  
        const response = await getUsersPage(postData); 
        this.responseData = response;
        this.users = response.data.list;
      } catch (error) {  
        console.error('发送 POST 请求时出错:', error);
      }
    },
    async addUser() {
      try {
        const postData = {  
          username: this.addUsername,
          password: this.addPassword,
          roleId: this.addRoleId, 
        };  
        const response = await addUser(postData); 
        this.responseData = response;
        this.getUsers(0);
        this.changeShow();
        this.addUsername = null;
        this.addPassword = null;
        this.addRoleId = null;
        alert("添加成功")
      } catch (error) {  
        console.error('发送 POST 请求时出错:', error);
      }
    },
    changeShow() {
      this.show = !this.show;
    }
  }
}
</script>

<style scoped>
.admin-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.btn {
  padding: 8px 15px;
  margin: 5px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  font-size: 14px;
}

.btn:hover {
  background-color: #5a6268;
}

.add-user, .search {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 4px;
}

.search-fields {
  display: flex;
  flex-direction: column; 
  gap: 10px; 
}

/* 字段组样式 */
.field-group {
  display: flex;
  align-items: center; /* 垂直居中对齐 */
  margin-bottom: 10px; /* 与其他字段的间距 */
  gap: 10px; /* 字段之间的间距 */
}

/* 字段组中的标签样式 */
.field-group label,
.field-group span {
  margin-right: 10px; /* 标签和输入框之间的间距 */
  white-space: nowrap; /* 防止标签文本换行 */
}

/* 输入框样式 */
.input-field {
  padding: 6px 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  flex-grow: 1; /* 让输入框占据剩余空间 */
}

.article-item {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.article-info {
  display: flex;
  flex-direction: column;
}

.article-info p {
  margin-bottom: 8px;
}

.top {
  min-height: 300px;
}
</style>