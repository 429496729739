<template>
  <div class="register-container">
    <h2>注册</h2>
    <div class="form-group">
      账号: <input type="text" v-model="username" class="input-field">
    </div>
    <div class="form-group">
      密码: <input type="password" v-model="password" class="input-field">
    </div>
    <div class="form-group">
      确认密码: <input type="password" v-model="password2" class="input-field">
    </div>
    <div class="form-group">
      邮箱: <input type="text" v-model="email" class="input-field">
    </div>
    <div class="form-group">
      手机号: <input type="text" v-model="phoneNumber" class="input-field">
    </div>
    <button @click="Register" class="btn">注册</button> 
  </div>
</template>

<script>
import { register } from '@/api/api';

export default {
  name: 'MyRegister',
  data() {
    return {
      username: '',
      password: '',
      password2: '',
      email: '',
      phoneNumber: '',
    }
  },
  methods: {
    async Register() {
      try {  
        const postData = {  
          username: this.username,
          password: this.password,
          password2: this.password2,
          email: this.email,
          mobilePhoneNumber: this.phoneNumber,
        };  
        // 发送请求
        if(postData.password === postData.password2){
          const response = await register(postData);  
          if(response.code === 200){
            this.responseData = response;
            alert('注册成功！');
            // 路由到登录页面
            this.$router.push('/login');
          }
          else{
            alert(response.data.msg);
          }
        } else {
          alert('两次输入的密码不一致');
        }   
      } 
      catch (error) {  
        console.error('register请求时出错:', error);
      } 
    }
  }
}
</script>

<style scoped>
.register-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.btn {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007BFF;
  color: white;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.btn:hover {
  background-color: #0056b3;
}
</style>