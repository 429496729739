<template>
  <div class="App">
    <div class="top">
      <!--路由出口,组件展示的位置-->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
//导入组件
//import HmHeader from './components/HmHeader.vue';
export default {
  //局部注册组件:只有App.vue能使用
  components: {
    //'组件名':组件对象
  }
}
</script>

<style>
.App {
    width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
</style>